import type { TOrganization } from '../models'
import type { Organization } from '../types'

export const createOrganizationAdapter = (organization: Organization): TOrganization => {
  return {
    id: organization.id,
    address: organization.address,
    canCreateRequest: organization.can_create_corpo_requests,
    canManageOffices: organization.can_manage_offices,
    canManageUsers: organization.can_manage_users,
    canUseOffices: organization.can_use_offices,
    commentOrganizationAppIsActive: organization.comment_organization_app_is_active,
    createRequestIsActive: organization.create_request_is_active,
    departments: organization.departments,
    externalIdIsRequired: organization.external_id_is_required,
    externalIdLength: organization.external_id_length,
    externalIdName: organization.external_id_name,
    extraData: organization.extra_data,
    logo: organization.logo,
    isoLogo: organization.iso_logo,
    name: organization.name,
    reportEmail: organization.report_email,
    reportEmailRequestCreateDay: organization.report_email_request_create_days,
    responsible: organization.responsable,
    schemeSlug: organization.scheme_slug,
    showBudgets: organization.show_budgets_in_webapp_corpo,
    showChat: organization.show_chat,
    showExternalId: organization.show_external_id_in_app,
    showMilestones: organization.show_hitos,
    strategy: organization.strategy,
    lookerLink: organization.looker_link,
    showCorpoStats: organization.show_corpo_stats,
  }
}
