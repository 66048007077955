import { axiosInstance as axios } from '@/services'

export const AxiosInterceptor = () => {
  const updateHeader = (request: any) => {
    const token = localStorage.getItem('token')

    if (token && request.url !== '/login/') {
      const newHeaders = {
        Authorization: `JWT ${token}`,
      }

      request.headers = newHeaders
    }

    return request
  }

  axios.interceptors.request.use((request) => {
    return updateHeader(request)
  })

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}
